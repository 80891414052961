@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

:root {
    --bg-primary:#ffff;
  --sec-color: #F0F0F0;
  --border-color: #D9D9D9;
  --active-color: #FFD700;
  --main-color: #000000;
  --max-width: 1200px;
}

/* * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  direction: rtl;
  transition: all .1s ease-in-out;
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -ms-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
} */

.section__container {
  max-width: var(--max-width);
  margin: auto;
  padding: 5rem 1rem;
  /* background-color: var(--bg-primary); */
}

.section__header {
  margin-bottom: 0.5rem;
  font-size: 2rem;
  font-weight: 600;
  color: var(--text-dark);
}

.btn {
    padding: 0.5rem 1rem;
      outline: none;
  border: none;
  font-size: 1.4rem;
  white-space: nowrap;
  color: #fff !important;
  background-color: goldenrod;
  border-radius: 5px;
  cursor: pointer;
  transition: .3s background-color ease-in;
  -webkit-transition: .3s background-color ease-in;
  -moz-transition: .3s background-color ease-in;
  -ms-transition: .3s background-color ease-in;
  -o-transition: .3s background-color ease-in;
}
.btn:hover {
  background-color: #000;
}

.btn-banner {
  padding: 0.5rem 1rem;
    outline: none;
border: none;
font-size: 1.4rem;
white-space: nowrap;
color: #fff !important;
background-color: rgba(0 0 0 / .8);
border-radius: 5px;
cursor: pointer;
transition: .3s background-color ease-in;
-webkit-transition: .3s background-color ease-in;
-moz-transition: .3s background-color ease-in;
-ms-transition: .3s background-color ease-in;
-o-transition: .3s background-color ease-in;
border: 1px solid  rgba(0 0 0 / .8);
}
.btn-banner:hover {
background-color: transparent;
}

.btn-sec{
    padding: 0.5rem 1rem;
    outline: none;
border: none;
font-size: 1.4rem;
white-space: nowrap;
color: #000 !important;
background-color: transparent;
border-radius: 5px;
cursor: pointer;
border: 1px solid goldenrod;
transition: .3s background-color ease-in;
-webkit-transition: .3s background-color ease-in;
-moz-transition: .3s background-color ease-in;
-ms-transition: .3s background-color ease-in;
-o-transition: .3s background-color ease-in;
}
.btn-sec:hover{
    background-color: goldenrod;
    color: #fff !important;
}
img {
  width: 100%;
  display: flex;
}

a {
  text-decoration: none;
}

body {
  font-family: "Poppins", sans-serif;
}

header {
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 660px;
}

.nav__container {
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;

}

.nav__logo {
  font-size: 2.2rem;
  font-weight: 600;
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 5px;
}

.nav__logo>img {
    width: 160px;
      /* height: 50px; */
  object-fit: cover;
  object-position: center;
}

.nav__logo span {
  color: var(--secondary-color);
}

.nav__links {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.link {
  transition: border-bottom .2s ease-in-out;
  -webkit-transition: border-bottom .2s ease-in-out;
  -moz-transition: border-bottom .2s ease-in-out;
  -ms-transition: border-bottom .2s ease-in-out;
  -o-transition: border-bottom .2s ease-in-out;
}

.link a {
  padding: 0.5rem;
  color: var(--main-color);
  font-size: 24px;
  color: #ffff;
  font-weight: 700;
}

.fixed-header {
    position: fixed;
    background-color: rgb(0 0 0 / .5);
    width: 100%;
    padding: 0rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 55555555555555555;
    color: #000 !important;
    top: 0 !important;
}

.link a:hover {
  color: var(--active-color);
}

.active-link-land {
  border-bottom: 3px solid var(--active-color);
}

.header__container {
  display: flex;
  align-items: center;
  gap: 5rem;
}

.header__container h1 {
  margin-bottom: 1rem;
  max-width: 800px;
  font-size: 3.5rem;
  line-height: 4rem;
  color: #fff;
}

.header__container p {
  margin-bottom: 1rem;
  max-width: 600px;
  color: var(--sec-color);
  font-size: 28px;
  font-weight: 600;
}

.header__form {
  width: 100%;
  max-width: 850px;
  margin: 50px auto;
}

.header__form form {
  display: grid;
  gap: 1rem;
  padding: 2rem;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
}

.header__form input,
.header__form select,
.header__form textarea {
  padding: 1rem;
  outline: none;
  border: none;
  font-size: 1rem;
  color: var(--primary-color) !important;
  background-color: var(--primary-color-light) !important;
  border-radius: 5px;
  width: 100%;
}

.header__form input::placeholder,
.header__form textarea::placeholder {
  color: var(--primary-color);
}

.form__btn {
  background-color: var(--primary-color);
  transition: 0.3s;
}

.form__btn:hover {
  background-color: var(--secondary-color);
}

.header__form h4 {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary-color);
}

.service__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.service__header__content p {
  max-width: 600px;
  color: var(--text-light);
  font-size: 1.368rem;
}

.service__btn {
  padding: 0.75rem 1rem;
  outline: none;
  font-size: 1rem;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-color: transparent;
  border-radius: 5px;
  white-space: nowrap;
  cursor: pointer;
  transition: 0.3s;
}

.service__btn:hover {
  color: var(--white);
  background-color: var(--primary-color);
}

.service__grid {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
}


.service__card {
  padding: 2rem;
  text-align: center;
  border-radius: 10px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.service__card .image {
  overflow: hidden;
}

.service__card img {
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  transition: transform 0.3s ease;
  -webkit-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  -ms-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  height: 260px;
  object-fit: cover;
  object-position: center;
}

.service__card img:hover {
  transform: scale(1.1);
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
}
.service__container{
background-color: #fff !important;
}
.service__card span {
  display: inline-block;
  margin-bottom: 1rem;
  padding: 10px 20px;
  font-size: 2.5rem;
  color: var(--primary-color);
  background-color: var(--primary-color-light);
  border-radius: 100%;
  transition: 0.3s;
}

.service__card h4 {
    margin-bottom: 0.5rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--text-dark);
    margin-top: 15px;
}

.service__card p {
  margin-bottom: 1rem;
  color: var(--text-light);
  font-size: 1.3rem;
}

.service__card a {
  color: var(--primary-color);
}

.service__card a:hover {
  color: var(--secondary-color);
}

.service__card:hover span {
  color: var(--primary-color-light);
  background-color: var(--primary-color);
}

.about__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  align-items: center;
}
.about__content ul{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: self-start;
    position: relative;
}
.about__content ul li {
  margin-bottom: 1rem;
  color: #555;
  font-size: 1.368rem;
  position: relative;
  padding-right: 40px;
}
.about__content ul li::before{
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border-radius: 50%;
    right: 0;
    top: 5px;
    border: 5px solid goldenrod;
}

.about__image img {
  max-width: 400px;
  margin: auto;
  border-radius: 10px;
}

.why__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  align-items: center;
}

.why__image img {
  max-width: 400px;
  margin: auto;
  border-radius: 10px;
}

.why__content p {
  color: var(--text-light);
}

.why__grid {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 80px auto;
  gap: 2rem;
}

.why__grid span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  color: var(--primary-color);
  border-radius: 10px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.why__grid h4 {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--text-dark);
}

.why__grid p {
  color: var(--text-light);
}

.doctors__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.doctors__header__content p {
  max-width: 600px;
  color: var(--text-light);
}

.doctors__nav {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.doctors__nav span {
  padding: 5px 15px;
  font-size: 1.5rem;
  color: var(--primary-color);
  background-color: var(--primary-color-light);
  cursor: pointer;
}

.doctors__grid {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.doctors__card {
  text-align: center;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
}

.doctors__card__image {
  position: relative;
  overflow: hidden;
}

.doctors__socials {
  position: absolute;
  left: 0;
  bottom: -4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  transition: 0.5s;
}

.doctors__socials span {
  display: inline-block;
  padding: 6px 12px;
  font-size: 1.5rem;
  color: var(--text-dark);
  background-color: var(--white);
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.doctors__socials span:hover {
  color: var(--primary-color);
}

.doctors__card:hover .doctors__socials {
  bottom: 2rem;
}

.doctors__card h4 {
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--text-dark);
}

.doctors__card p {
  margin-bottom: 1rem;
  color: var(--text-light);
}

.footer {
  background-color: rgb(0 0 0 / .9);
  color: #fff !important;
}

.footer__container {
  display: grid;
  grid-template-columns: 400px repeat(3, 1fr);
  gap: 2rem;
}

.footer__col h3 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--sec-color);
}

.footer__col h3 span {
  color: var(--sec-color);
}

.footer__col h4 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: #fff;
}

.footer__col p,
.footer__col a {
  margin-bottom: 1.2rem;
  color: var(--sec-color);
  cursor: pointer;
  transition: 0.3s;
}

.footer__col p:hover,
.footer__col a:hover {
  color: var(--sec-color);
}

.footer__col i {
  color: var(--sec-color);
}

.footer__bar {
  background-color: goldenrod;
  color: #000 !important;
  font-size: 21px !important;
}

.footer__bar__content {
  max-width: var(--max-width);
  margin: auto;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.footer__bar__content p {
  font-size: 0.8rem;
  color: #fff;
}

.footer__socials {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1rem;
  color: var(--white);
  cursor: pointer;
}

@media (width < 900px) {
  /* .service__grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  } */

  .about__container {
    grid-template-columns: repeat(1, 1fr);
  }

  .about__image {
    grid-area: 1/1/2/2;
  }

  .about__content {
    text-align: center;
  }

  .why__container {
    grid-template-columns: repeat(1, 1fr);
  }

  .why__content {
    text-align: center;
  }

  .why__grid {
    text-align: left;
  }

  .doctors__grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .footer__container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width < 992px) {
  .nav__links {
    display: none;
  }

  .header__container {
    flex-direction: column;
  }

  .btn-bar {
    display: block !important;
  }
}

@media (width < 600px) {
  .service__header {
    flex-direction: column;
    text-align: center;
  }

  /* .service__grid {
    grid-template-columns: repeat(1, 1fr);
  } */

  .why__grid {
    column-gap: 1rem;
  }

  .doctors__header {
    flex-direction: column;
    text-align: center;
  }

  .doctors__grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .footer__bar__content {
    flex-direction: column;
    gap: 1rem;
  }

  .header__container p {
    font-size: 17px;
  }
}

.overlay-drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  right: -100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  transition: right ease-in .2s;
}
.overlay-drawer-active-land{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  transition: right ease-in .2s;
  right: 0%;
}
.btn-bar {
  display: none;
}

.drawer {
  position: fixed;
  top: 0;
  left: -300px;
  max-width: 300px;
  height: 100%;
  background-color:rgb(0 0 0 / .82);
  transition: left 0.3s ease;
  z-index: 1000;
  width: 100%;
  z-index: 55555555555555555;
}

.drawer.open {
  left: 0;
}

.drawer ul {
  list-style-type: none;
  padding: 0;
  margin-top: 40px;
  /* مسافة بين الشعار والروابط */
}

.drawer ul li {
  padding: 10px 20px;
}

.drawer ul li a {
  text-decoration: none;
  color: #ffff;
}

.drawer .drawer__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #ccc;
}

.drawer .drawer__logo {
  font-weight: bold;
  font-size: 24px;
  margin-right: 20px;
}

.drawer .drawer__close {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
}

.drawer .drawer__socials {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.drawer .drawer__socials svg {
  font-size: 24px;
  margin: 0 10px;
  color: #fff;
}





.pricing-cta-wrapper {
  position: relative;
  z-index: 1;
}

.pricing-cta-wrapper::before {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  background: #001659;
  opacity: .6;
  z-index: -1;
}

.pricing-cta-wrapper .pricing-contents h1 {
  display: block;
  margin-top: -15px;
}

.pricing-cta-wrapper .pricing-contents a {
  line-height: 1px;
  padding: 28px 45px;
  display: inline-block;
  border: 2px solid #fff;
  text-transform: uppercase;
  overflow: hidden;
  margin-right: 30px;
  margin-top: 25px;
}

.pricing-cta-wrapper .pricing-contents a:hover {
  color: #FFF;
  background: #FF5E14;
  border-color: #FF5E14;
}

.pricing-cta-wrapper .pricing-contents .btn-cta {
  display: inline-block;
  overflow: hidden;
  clear: both;
  margin-bottom: -15px;
  margin-top: 25px;
}

.pricing-cta-wrapper .pricing-contents .btn-cta .icon {
  float: left;
  overflow: hidden;
  margin-right: 20px;
  font-size: 60px;
  color: #FF5E14;
}

.pricing-cta-wrapper .pricing-contents .btn-cta .content {
  overflow: hidden;
  text-align: left;
}

.scroll-btn-wrapper {
  position: fixed;
  right: 5px;
  bottom: 15px;
  background: goldenrod;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 25px;
  border-radius: 50%;
  z-index: 555;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  z-index: 555;
  cursor: pointer;
  padding-bottom: 5px;
}

@media(min-width:992px) {
  .appointment {
    background-color: #fff;
    max-width: 1200px;
    width: 90%;
    margin: 80px auto;
    border: 1px solid #ffffff;
    box-shadow: 2px 2px 30px rgb(0 0 0 / .12);
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width:600px) {
  .appointment {
    width: 100% !important;
  }

  .table-appointment-wrapper {
    width: 100%;
    overflow-x: auto;
  }

  .apointment-text {
    padding: 50px 20px !important;
  }

  .contact-us {
    padding: 0 0px !important;
    padding-bottom: 0;
  }

  .contact-us>h2,
  .contact-us>p {
    padding: 0 20px !important;
  }

}

@media(max-width:992px) {
  .appointment {
    background-color: #fff;
    max-width: 1200px;
    width: 90%;
    margin: 80px auto;
    border: 1px solid #ffffff;
    box-shadow: 2px 2px 30px rgb(0 0 0 / .12);
    display: grid;
    grid-template-columns: 1fr;
    overflow: hidden;
  }

  .apointment-video img {
    max-height: 452px;
    object-fit: cover;
    object-position: center;
  }
}

.apointment-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.apointment-text {
  padding: 50px;
}

.appointment-text h2 {
  font-size: 2.8rem;
  color: #122853;
  line-height: 3rem;
}

.appointment-text p {
  font-size: 0.9rem;
  color: #535353;
  line-height: 1.4rem;
  letter-spacing: .3px;
}

.box-day {
  /* border: 1px solid #eee; */
  background-color: #f5f7fb;
  display: flex;
  justify-content: space-between;
  padding: .5rem;
  border-radius: 5px;
  margin: 5px 0;
}

.table-appointment-wrapper {
  margin-top: 20px;
  border: 1px solid rgb(0 112 205 / .8);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.gallery {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: var(--section-background);
  position: relative;
  padding-left: 80px;
  padding-right: 80px;
}

@media(max-width:476px) {
  .gallery {
    padding-left: 5px !important;
    padding-right: 5px !important;
    overflow: hidden;
  }

  .gallery .container .box .image img {
    max-width: 270px !important;
    width: 100% !important;
  }
  .section__container-mt-100{
    margin-top: 100px;
  }
}

.gallery .container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 40px;
}

.gallery .container .box {
  padding: 15px;
  background-color: #fff;
}

.gallery .container .box .image {
  position: relative;
  overflow: hidden;
}

.gallery .container .box .image::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255 255 255 / 40%);
  height: 0%;
  width: 0%;
  opacity: 0;
  z-index: 2;
}

.gallery .container .box .image:hover::before {
  animation: flashing var(--main-transition);
  -webkit-animation: flashing var(--main-transition);
}

.gallery .container .box .image img {
  max-width: 100%;
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.gallery .container .image:hover img {
  transform: rotate(5deg) scale(1.1);
}

.contact-us {
  padding: 110px 70px;
  padding-bottom: 0;
  overflow: hidden !important;
}

section {
  overflow: hidden !important;
}

@media screen and (max-width:468px) {
  .header__content {
    margin-top: -120px;
  }

  .header__container h1 {
    font-size: 2.1rem !important;
  }
}



::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #a5a5a5;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

li {
  list-style: none;
}

.footer__col li a {
  color: #fff;
  font-size: 16px;
  font-weight: 100;
}

.footer__col li {
  padding-bottom: 8px;
}

.footer__col li a:hover {
  color: var(--primary-color);
}

a {
  color: #fff;
}




.team {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
}

.team .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
}

.team .box {
    position: relative;
}

.team .box::before,
.team .box::after {
    content: "";
    background-color: #f3f3f3;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border-radius: 10px;
    transition: .5s all;
    -webkit-transition: .5s all;
    -moz-transition: .5s all;
    -ms-transition: .5s all;
    -o-transition: .5s all;
}

.team .box::before {
    width: calc(100% - 60px);
    z-index: 0;
}

.team .box::after {
    z-index: -1;
    background-color: #e4e4e4;
    width: 0;
}

.team .box:hover::after {
    width: calc(100% - 60px);
}

.team .box .data {
    display: flex;
    align-items: center;
    padding-top: 60px;
}

.team .box .data img {
    width: calc(100% - 60px);
    transition: all .5s;
    border-radius: 10px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    margin-right: 40px;
    z-index: 1;
}

.team .box:hover img {
    filter: grayscale(50%);
    -webkit-filter: grayscale(50%);
}

.team .box .data .social {
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: absolute;
    right: -10px;
}

.team .box .data .social a {
    width: 60px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
}

.team .box .data .social a:hover svg {
    color: var(--primary-color);
}

.team .box .data .social svg {
    color: var(--primary-color);
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
}
.team .box:hover .data .social svg,
.team .box:hover .info h3 {
    color: var(--secondary-color);
}

.team .box .info {
  padding-right: 40px;
  position: relative;
  width: calc(100% - 100px);
  margin-top: 15px;
}

.team .box .info h3 {
    margin-bottom: 0;
    color: #000 !important;
    font-size: 1.368rem;
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
}

.team .box .info p {
    margin-top: 10px;
    margin-bottom: 25px;
    color: #000 !important;
    font-size: 1.3rem;
}
.main_title_Articles{
    margin-bottom: 40px;
}
.flex-btn-contact{
    display: flex;
    gap: 10px;
    white-space: nowrap;
}
.mt-15{
    margin-top: 40px;
}
.flex-btn-contact-between{
    display: flex;
    gap: 10px;
    white-space: nowrap;
    justify-content: space-between;
    margin-bottom: 40px;
}



.features {
  padding-top: 20px;
  padding-bottom: 100px;
  position: relative;
  background-color: #fff;
  margin-top: 20px !important  ;
}

.features .container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 40px;
}

.features .container .box {
  text-align: center;
  border: 1px solid #eee;
}

.features .container .img-holder {
  position: relative;
  overflow: hidden;
}

.features .container .img-holder::before {
  content: " ";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.features .container .box .img-holder::after {
  content: " ";
  position: absolute;
  bottom: 0;
  right: -3px;
  border-style: solid;
  border-width: 0px 0px 170px 521px;
  border-color: transparent transparent #fff transparent;
  transition: .3s ease-in all;
  -webkit-transition: .3s ease-in all;
  -moz-transition: .3s ease-in all;
  -ms-transition: .3s ease-in all;
  -o-transition: .3s ease-in all;
}

.features .container .box:hover .img-holder::after {
  border-width: 170px 521px 170px 0px;
}

.features .container .img-holder img {
  max-width: 100%;
  height: 450px;
  object-fit: cover;
  object-position: center;}

.features .container .box h2 {
  font-size: 40px;
  font-weight: 600;
  position: relative;
  margin: auto;
  width: fit-content;
}

.features .container .box h2::before {
  content: " ";
  position: absolute;
  bottom: -20px;
  left: 15px;
  width: calc(100% - 30px);
  height: 5px;
  background-color: rgba(0 0 0 / .8);
}

.features .container .box p {
  padding: 25px;
  line-height: 2;
  font-size: 20px;
  color: #777;
  margin: 0 30px;
}

.features .container .box a {
  display: block;
  border: 2px solid rgba(0 0 0 / .8);
  width: fit-content;
  padding: 10px 30px;
  margin: 0 auto 30px;
  font-size: 22px;
  color: rgba(0 0 0 / .8);
  border-radius: 6px;
}

.features .container .quality .img-holder::before {
  background-color: #5555;
}

.features .container .quality h2::before {
  color: rgba(0 0 0 / .8) !important;
}

.features .container .quality a {
  color: #fff;
  border-color: rgba(0 0 0 / .8);
  background: linear-gradient(to right, rgba(0 0 0 / .8) 50%, #fff 50%);
  background-size: 200% 100%;
  background-position: right bottom;
}

.features .container .box:hover a {
  background-position: left bottom;
  color: white !important;
  transition:  .3s ease-in all;
  -webkit-transition:  .3s ease-in all;
  -moz-transition:  .3s ease-in all;
  -ms-transition:  .3s ease-in all;
  -o-transition:  .3s ease-in all;
}

.features .container .time .img-holder::before {
  background-color: rgb(218 165 32 / .3);
}

.features .container .time h2::before {
  background-color: rgb(218 165 32);
}

.features .container .time a {
  color: rgb(218 165 32);
  border-color: rgb(218 165 32);
}

.features .container .time a {
  color: #fff;
  border-color: rgb(218 165 32);
  background: linear-gradient(to right, rgb(218 165 32) 50%, #fff 50%);
  background-size: 200% 100%;
  background-position: right bottom;
}

.features .container .passion .img-holder::before {
  background-color: rgba(0 0 0 / .4);
}

.features .container .passion h2::before {
  background-color: rgba(0 0 0 / .8);
}

.features .container .passion a {
  color: #000 !important;
  border-color: rgba(0 0 0 / .8);
}

.features .container .passion a {
  color: #000 !important;
  border-color: rgba(0 0 0 / .8);
  background: linear-gradient(to right, rgba(0 0 0 / .8) 50%, #fff 50%);
  background-size: 200% 100%;
  background-position: right bottom;
}


.swiper{
  width: 100% !important;
}
@media screen and (max-width: 469px)  {
  .swiper{
    padding-top: 111px !important;
  }
}
.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 23px !important;
}
.swiper-button-prev, .swiper-button-next {
  color: white !important;
}


.sidebar-search{
  border: 1px solid #eee;
  padding: 5px 4px;
  margin-top: 5px;
  width: 100%;
  border-radius: 7px;
}
.sidebar-search>input{
  width:100% !important;
  outline: 0 !important
}